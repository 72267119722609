<template>
  <section>
    <b-card title="Engagement & Activity this month">
      <b-row>
        <b-col sm="8">
          <div class="d-flex align-items-center ml-50">
            <b-avatar
              size="48"
              variant="light-primary"
              class="mr-2"
            >
              <feather-icon icon="BarChartIcon" />
            </b-avatar>
            <div class="d-flex flex-column">
              <h4 class="font-weight-bolder mb-0">
                {{ totalSessions }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                Sessions
              </p>
            </div>
            <b-avatar
              size="48"
              variant="light-success"
              class="mr-2 ml-3"
            >
              <feather-icon icon="LayersIcon" />
            </b-avatar>
            <div class="d-flex flex-column">
              <h4 class="font-weight-bolder mb-0">
                {{ totalLearningModules }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                Module Engagements
              </p>
            </div>
            <b-avatar
              size="48"
              variant="light-success"
              class="mr-2 ml-3"
            >
              <feather-icon icon="DownloadIcon" />
            </b-avatar>
            <div class="d-flex flex-column">
              <h4 class="font-weight-bolder mb-0">
                {{ totalDownloads }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                Resources
              </p>
            </div>
            <b-avatar
              v-if="!isProgramTraining"
              size="48"
              variant="light-secondary"
              class="mr-2 ml-3"
            >
              <feather-icon icon="SendIcon" />
            </b-avatar>
            <div v-if="!isProgramTraining" class="d-flex flex-column">
              <h4 class="font-weight-bolder mb-0">
                {{ totalComms }}
              </h4>
              <p class="card-text font-small-3 mb-0">
                Communications
              </p>
            </div>
          </div>
        </b-col>
        <b-col sm="4">
          <div class="d-flex justify-content-end">
            <b-form-group 
              v-if="!isProgramTraining"
              label="Select Groups to filter by" 
              label-for="groups"
              class="mr-1 mb-0"
            >
              <aom-group-filter
                v-model="selectedGroup"
                :program-id="$route.params.id"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <daily-logins-report
      :group-id="selectedGroup"
      @count="updateSessionsCount"
    />
    <daily-learning-report
      v-if="isProgramTraining"
      :group-id="selectedGroup"
      @count="updateLearningModules"
    />
    <daily-downloads-report
      :group-id="selectedGroup"
      @count="updateDownloadsCount"
    />
    <daily-comms-report
      v-if="!isProgramTraining"
      :group-id="selectedGroup"
      @count="updateCommsCount"
    />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import DailyLoginsReport from './DailyLogins.vue';
import DailyLearningReport from './DailyLearningReport.vue';
import DailyDownloadsReport from './DailyDownloads.vue';
import DailyCommsReport from './DailyCommsReport.vue';
import {
  BCard,
  BFormGroup,
  BAvatar, 
  BRow,
  BCol
} from 'bootstrap-vue';
import AomGroupFilter from '@aom-core/AomGroupFilter.vue';
import {programTypes} from '@models';

export default {
  name: 'ReportsOverview',
  components: {
    DailyLoginsReport,
    DailyDownloadsReport,
    DailyCommsReport,
    BCard,
    AomGroupFilter,
    BFormGroup, 
    BAvatar,
    BRow,
    BCol,
    DailyLearningReport
  },
  computed: {
    ...mapGetters("programs", ["defaultProgram"]),
    isProgramTraining () {
      return this.defaultProgram?.type_id === programTypes.TRAINING;
    },
  },
  data() {
    return {
      selectedGroup: undefined,
      totalComms: 0,
      totalDownloads: 0,
      totalSessions: 0,
      totalLearningModules: 0
    };
  }, 
  methods: {
    updateSessionsCount(val) {
      this.totalSessions = val;
    },
    updateDownloadsCount(val) {
      this.totalDownloads = val;
    },
    updateCommsCount(val) {
      this.totalComms = val;
    },
    updateLearningModules(val) {
      this.totalLearningModules = val;
    }
  }
};
</script>
